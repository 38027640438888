import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';

import eyeHiddenIcon from '../../assets/images/eye-hidden.png';
import eyeShownIcon from '../../assets/images/eye-shown.png';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import PrimaryActionButton from '../../components/Buttons/PrimaryActionButton';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import InputField from '../../components/Inputs/InputField';
import RedirectLink from '../../components/Links/RedirectLink';
import useAuth from '../../hooks/useAuth';
import { usePermissions } from '../../hooks/usePermissions';
import { authenticateUser } from '../../services/AuthService';
import UserService from '../../services/UserService';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isAuth, setIsAuth] = useAuth();
  const { userPermissions, changePermissions } = usePermissions();

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await authenticateUser(email, password);

    if (response.success) {
      const currentUserData = await UserService.getCurrentUserData();
      changePermissions(currentUserData.data);
      setIsAuth(true);
    } else {
      setError(response.error.error || 'Authentication failed. Please try again.');
    }
  };

  if (isAuth) {
    if (userPermissions && userPermissions.displayDashboard == true) {
      return <Redirect to="/dashboard" />;
    } else {
      return <Redirect to="/users" />;
    }
  }

  return (
    <AuthLayout
      title="Welcome back!"
      link={<RedirectLink to="/forgot-password">Forgot your password?</RedirectLink>}
    >

      <form onSubmit={handleLogin}>
        <InputField
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <InputField
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          toggleIcon={showPassword ? eyeShownIcon : eyeHiddenIcon}
          onToggleIconClick={() => setShowPassword(!showPassword)}
        />

        <ErrorMessage messages={error} />

        <PrimaryActionButton type='submit'>SIGN IN</PrimaryActionButton>
      </form>

    </AuthLayout>
  );
}
export default Login;
