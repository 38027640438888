import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import styles from './CompanyList.module.scss';
import CompanyDetailsForm from '../../components/CompanyDetailsForm/CompanyDetailsForm';
import ListComponent from '../../components/ListComponent/ListComponent';
import MainLayout from '../../components/MainLayout/MainLayout';
import Spinner from '../../components/Spinner/Spinner';
// import { PAPERPLANES_ID } from '../../config';
// import { usePermissions } from '../../hooks/usePermissions';
import CompanyService from '../../services/CompanyService';

function CompanyList() {
  const [companyList, setCompanyList] = useState(null);
  const [createCompanyPopUpOpen, setCreateCompanyPopUpOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const {userPermissions} = usePermissions();
  // const isPPAdmin = userPermissions.role === 'Admin' &&
  // userPermissions.companyId === PAPERPLANES_ID;

  const columns = [
    'name', 'mIdsite', 'userCount', 'createdAt', 'status', 'companyActions',
  ];

  const processErrorResponse = (response, defaultMessage) => {
    if (response.error && response.error.errors) {
      return response.error.errors.map((err) => err.detail).join('\n');
    }
    return defaultMessage;
  };

  const createCompany = async (data) => {
    // convert urls and ip address inputs to list type
    ['excluded_ip_addresses', 'urls'].forEach((key) => {
      if (data[key]) data[key] = [data[key]];
    });
    try {
      const createCompanyResponse = await CompanyService.createCompany(data);

      if (createCompanyResponse.success) toast.success('Company Created successfully!');
      else {
        toast.error(
            processErrorResponse(createCompanyResponse, 'Error creating a new company'),
        );
      }
    } catch (e) {
      toast.error('An error occurred creating a new company');
    }
    setCreateCompanyPopUpOpen(false);
  };

  const loadCompanyList = async () => {
    setLoading(true);
    const getCompanyListResult = await CompanyService.getCompanyList();

    if (getCompanyListResult.success) {
      setCompanyList(getCompanyListResult.data.map((company) => ({
        ...company,
        refresh: loadCompanyList,
      })));
    } else {
      toast.error('Failed to load companies. Please try again.');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadCompanyList();
  }, []);

  return (
    <MainLayout>
      <div className={styles.companyTableHeader}>
        <h1 className={styles.heading}>Companies</h1>
        {/* {isPPAdmin && <button
          className={styles.addCompanyButton}
          onClick={() => setCreateCompanyPopUpOpen(true)}
        >Add Company</button>} */}
        <CompanyDetailsForm
          popUpOpen={createCompanyPopUpOpen}
          setPopUpOpen={setCreateCompanyPopUpOpen}
          formTitle='Add Company'
          onSubmit={(data) => createCompany(data)}
        />
      </div>
      <div style={{overflow: 'auto', height: '80vh'}}>
        {!loading && companyList && (<ListComponent columns={columns} data={companyList}/>)}
      </div>
      <Spinner loading={loading} />
    </MainLayout>
  );
}

export default CompanyList;
