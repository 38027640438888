import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import styles from './ConnectKeys.module.scss';
import klaviyoLogo from '../../assets/images/klaviyo-logo.png';
import ometriaLogo from '../../assets/images/ometria-logo.png';
import rechargeLogo from '../../assets/images/recharge-logo.png';
import shopifyLogo from '../../assets/images/shopify-logo.png';
import PrimaryActionButton from '../../components/Buttons/PrimaryActionButton';
import { DataSourceConnectionContent } from
  '../../components/DataSourceConnectionContent/DataSourceConnectionContent';
import MainLayout from '../../components/MainLayout/MainLayout';
import ModalMultipartForm from '../../components/Modal/ModalMultipartForm';
import {
  SHOPIFY_CONFLUENCE,
  KLAVIYO_CONFLUENCE,
  OMETRIA_API_KEY_DOC,
  RECHARGE_API_DOC,
} from '../../config';
import ApiKeyService from '../../services/ApiKeyService';

const accounts = {
  shopify: {
    logo: shopifyLogo,
    document: SHOPIFY_CONFLUENCE,
    text: 'Shopify store',
  },
  klaviyo: {
    logo: klaviyoLogo,
    document: KLAVIYO_CONFLUENCE,
    text: 'Klaviyo account',
  },
  ometria: {
    logo: ometriaLogo,
    document: OMETRIA_API_KEY_DOC,
    text: 'Ometria account',
  },
  recharge: {
    logo: rechargeLogo,
    document: RECHARGE_API_DOC,
    text: 'Recharge account',
  },
};

const BoxDesign = ({ name, onClick, exists, loading }) => {
  return (
    <div className={styles.connectContainer}>
      <div className={styles.connectInfo}>
        <div>
          <img src={accounts[name].logo} />
        </div>
        <div>
          <span>{name[0].toUpperCase() + name.slice(1)}</span> <br />
          Connect Paperplanes to your {accounts[name].text}.
          <a href={accounts[name].document}
            target="_blank" rel="noopener noreferrer">Learn More</a>
        </div>
      </div>

      {!loading && !exists && <PrimaryActionButton
        className={styles.connectButton}
        type='secondary'
        onClick={onClick}
      >
        Connect
      </PrimaryActionButton>}

      {!loading && exists && <div>
        <p>Already connected</p>
      </div>}
    </div>
  );
};

function ConnectKeys() {
  const [shopifyFormOpen, setShopifyFormOpen] = useState(false);
  const [shopifyIndexForm, setshopifyIndexForm] = useState();
  const [shopifyFormData, setShopifyFormData] = useState({});
  const [klaviyoFormOpen, setKlaviyoFormOpen] = useState(false);
  const [klaviyoIndexForm, setKlaviyoIndexForm] = useState();
  const [klaviyoFormData, setKlaviyoFormData] = useState({});
  const [klaviyoSecretExist, setKlaviyoSecretExist] = useState(false);
  const [shopifySecretExist, setShopifySecretExist] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(true);
  const [ometriaFormOpen, setOmetriaFormOpen] = useState(false);
  const [ometriaIndexForm, setOmetriaIndexForm] = useState();
  const [ometriaFormData, setOmetriaFormData] = useState({});
  const [ometriaSecretExist, setOmetriaSecretExist] = useState(false);
  const [rechargeFormOpen, setRechargeFormOpen] = useState(false);
  const [rechargeIndexForm, setRechargeIndexForm] = useState();
  const [rechargeFormData, setRechargeFormData] = useState({});
  const [rechargeSecretExist, setRechargeSecretExist] = useState(false);


  const checkSecretAlreadyExist = async () => {
    const checkKlaviyoSecret = await ApiKeyService.checkApiKey('klaviyo');
    const checkShopifySecret = await ApiKeyService.checkApiKey('shopify');
    const checkOmetriaSecret = await ApiKeyService.checkApiKey('ometria');
    const checkRechargeSecret = await ApiKeyService.checkApiKey('recharge');
    if (checkKlaviyoSecret.success) {
      setKlaviyoSecretExist(checkKlaviyoSecret.data.exists);
    } else {
      toast.error('Failed to check whether Klaviyo Secret already exist.');
    }
    if (checkShopifySecret.success) {
      setShopifySecretExist(checkShopifySecret.data.exists);
    } else {
      toast.error('Failed to check whether Shopfiy Secret already exist.');
    }
    if (checkOmetriaSecret.success) {
      setOmetriaSecretExist(checkOmetriaSecret.data.exists);
    } else {
      toast.error('Failed to check whether Ometria Secret already exist.');
    }
    if (checkRechargeSecret.success) {
      setRechargeSecretExist(checkRechargeSecret.data.exists);
    } else {
      toast.error('Failed to check whether Recharge Secret already exist.');
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    checkSecretAlreadyExist();
  }, []);

  const uploadShopifyCreds = async (url, token, key) => {
    const body = {
      'service': 'shopify',
      'secret': {
        'url': url,
        'token': token,
        'key': key,
      },
    };
    const response = await ApiKeyService.uploadApiKey(body);
    if (response.success) {
      checkSecretAlreadyExist();
      return true;
    } else toast.error('Failed to upload Shopify Secret.');
    return false;
  };

  const uploadKlaviyoCreds = async (api) => {
    const body = {
      'service': 'klaviyo',
      'secret': {
        'api': api,
      },
    };
    const response = await ApiKeyService.uploadApiKey(body);
    if (response.success) {
      checkSecretAlreadyExist();
      return true;
    } else toast.error('Failed to upload Klaviyo Secret.');
    return false;
  };

  const uploadOmetriaCreds = async (apiTitle, api) => {
    const body = {
      'service': 'ometria',
      'secret': {
        'api_title': apiTitle,
        'api': api,
      },
    };
    const response = await ApiKeyService.uploadApiKey(body);
    if (response.success) {
      checkSecretAlreadyExist();
      return true;
    } else toast.error('Failed to upload Ometria Secret.');
    return false;
  };

  const uploadRechargeCreds = async (token) => {
    const body = {
      'service': 'recharge',
      'secret': {
        'token': token,
      },
    };
    const response = await ApiKeyService.uploadApiKey(body);
    if (response.success) {
      checkSecretAlreadyExist();
      return true;
    } else toast.error('Failed to upload Recharge Secret.');
    return false;
  };

  return (
    <MainLayout>
      <BoxDesign
        name='shopify'
        onClick={() => setShopifyFormOpen(true)}
        exists={shopifySecretExist}
        loading={buttonLoading}
      />
      <BoxDesign
        name='klaviyo'
        onClick={() => setKlaviyoFormOpen(true)}
        exists={klaviyoSecretExist}
        loading={buttonLoading}
      />
      <BoxDesign
        name='ometria'
        onClick={() => setOmetriaFormOpen(true)}
        exists={ometriaSecretExist}
        loading={buttonLoading}
      />
      <BoxDesign
        name='recharge'
        onClick={() => setRechargeFormOpen(true)}
        exists={rechargeSecretExist}
        loading={buttonLoading}
      />


      <ModalMultipartForm
        isOpen={shopifyFormOpen}
        title='Add Shopify API Keys'
        onSubmit={() => setShopifyFormOpen(false)}
        onClose={() => setShopifyFormOpen(false)}
        formData={shopifyFormData}
        setFormData={setShopifyFormData}
        indexFormPageOpen={shopifyIndexForm}
        setIndexFormPageOpen={setshopifyIndexForm}
        formConfigs={[
          {
            execute: uploadShopifyCreds,
            execArgsFormKeys: ['store_url', 'token', 'api_key'],
            config: [
              {
                name: 'store_url',
                label: 'Store URL',
                type: 'text',
                placeholder: 'Enter the Store URL with .myshopify.com',
                validation: {
                  required: 'Empty!',
                },
              },
              {
                name: 'token',
                label: 'Admin API Access token',
                type: 'text',
                placeholder: 'e.g shpat_4324221eef7b44c7cjd89d2c7hs7U55',
                validation: {
                  required: 'Empty!',
                },
              },
              {
                name: 'api_key',
                label: 'API Key',
                type: 'text',
                placeholder: 'e.g b4529fc191v54g2f1512c5903bg16587',
                validation: {
                  required: 'Empty!',
                },
              },
            ],
          },
          {
            title: 'Shopify API Keys',
            content: <DataSourceConnectionContent />,
          },
        ]}
      />

      <ModalMultipartForm
        isOpen={klaviyoFormOpen}
        title='Add Klaviyo API Key'
        onSubmit={() => setKlaviyoFormOpen(false)}
        onClose={() => setKlaviyoFormOpen(false)}
        formData={klaviyoFormData}
        setFormData={setKlaviyoFormData}
        indexFormPageOpen={klaviyoIndexForm}
        setIndexFormPageOpen={setKlaviyoIndexForm}
        formConfigs={[
          {
            execute: uploadKlaviyoCreds,
            execArgsFormKeys: ['api'],
            config: [
              {
                name: 'api',
                label: 'API Key',
                type: 'text',
                placeholder: 'e.g b4529fc191v54g2f1512c5903bg16587',
                validation: {
                  required: 'Empty!',
                },
              },
            ],
          },
          {
            title: 'Klaviyo API Key',
            content: <DataSourceConnectionContent />,
          },
        ]}
      />

      <ModalMultipartForm
        isOpen={ometriaFormOpen}
        title='Add Ometria API Key'
        onSubmit={() => setOmetriaFormOpen(false)}
        onClose={() => setOmetriaFormOpen(false)}
        formData={ometriaFormData}
        setFormData={setOmetriaFormData}
        indexFormPageOpen={ometriaIndexForm}
        setIndexFormPageOpen={setOmetriaIndexForm}
        formConfigs={[
          {
            execute: uploadOmetriaCreds,
            execArgsFormKeys: ['api_title', 'api'],
            config: [
              {
                name: 'api_title',
                label: 'API Key Title',
                type: 'text',
                placeholder: 'API key title configured on your account',
                validation: {
                  required: 'Empty!',
                },
              },
              {
                name: 'api',
                label: 'API Key',
                type: 'text',
                placeholder: 'e.g b4529fc191v54g2f1512c5903bg16587',
                validation: {
                  required: 'Empty!',
                },
              },
            ],
          },
          {
            title: 'Ometria API Key',
            content: <DataSourceConnectionContent />,
          },
        ]}
      />

      <ModalMultipartForm
        isOpen={rechargeFormOpen}
        title='Add Recharge API token'
        onSubmit={() => setRechargeFormOpen(false)}
        onClose={() => setRechargeFormOpen(false)}
        formData={rechargeFormData}
        setFormData={setRechargeFormData}
        indexFormPageOpen={rechargeIndexForm}
        setIndexFormPageOpen={setRechargeIndexForm}
        formConfigs={[
          {
            execute: uploadRechargeCreds,
            execArgsFormKeys: ['token'],
            config: [
              {
                name: 'token',
                label: 'API Token',
                type: 'text',
                placeholder: 'e.g b4529fc191v54g2f1512c5903bg16587',
                validation: {
                  required: 'Empty!',
                },
              },
            ],
          },
          {
            title: 'Recharge API Token',
            content: <DataSourceConnectionContent />,
          },
        ]}
      />

    </MainLayout>


  );
}

export default ConnectKeys;
