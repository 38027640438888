import React from 'react';

import {
  faPaperPlane, faChartSimple,
  faBriefcase, faUsers, faLink, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

import styles from './Sidebar.module.scss';
// import settingsIcon from '../../assets/images/menu-settings.png';
import { VERSION_NUMBER, PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';


function Sidebar() {
  const { userPermissions } = usePermissions();
  const selectedIcon = useLocation().pathname;
  const isAdmin = userPermissions.role === 'Admin';
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;
  const displayDashboard = userPermissions.displayDashboard;

  return (
    <div className={styles.sidebar}>
      {/* Temporary until an icon is provided */}
      {isPPAccount && <Link to={'/campaigns'} className={styles.menuItem}>
        <FontAwesomeIcon
          icon={faPaperPlane} alt='Campaigns'
          color={selectedIcon === '/campaigns' ? '#5b9bd4' : '#b7cbdc'} size='xl'
        />
      </Link>}
      {displayDashboard && <Link to={'/dashboard'} className={styles.menuItem}>
        <FontAwesomeIcon
          icon={faChartSimple} alt='Dashboard'
          color={selectedIcon === '/dashboard' ? '#5b9bd4' : '#b7cbdc'} size='xl'
        />
      </Link>}
      {isPPAccount && <Link to={'/companies'} className={styles.menuItem}>
        <FontAwesomeIcon
          icon={faBriefcase} alt='Companies'
          color={selectedIcon === '/companies' ? '#5b9bd4' : '#b7cbdc'} size='xl'
        />
      </Link>}
      {isAdmin && <Link to={'/users'} className={styles.menuItem}>
        <FontAwesomeIcon
          icon={faUsers} alt='Users'
          color={selectedIcon === '/users' ? '#5b9bd4' : '#b7cbdc'} size='xl'
        />
      </Link>}
      <Link to={'/upload'} className={styles.menuItem}>
        <FontAwesomeIcon
          icon={faUpload} alt='Upload'
          color={selectedIcon === '/upload' ? '#5b9bd4' : '#b7cbdc'} size='xl'
        />
      </Link>
      {isAdmin && !isPPAccount && <Link to={'/link_keys'} className={styles.menuItem}>
        <FontAwesomeIcon
          icon={faLink} alt='Connect Keys'
          color={selectedIcon === '/link_keys' ? '#5b9bd4' : '#b7cbdc'} size='xl'
        />
      </Link>}
      {/* <MenuItem to='/path-to-settings-page' icon={settingsIcon} alt='Settings'
        color={selectedIcon === '/path-to-settings-page' ? '#b7cbdc': '#000'}/> */}
      <footer className={styles.footerText}>Version {VERSION_NUMBER}</footer>
    </div>
  );
}

export default Sidebar;
