import React from 'react';

import { createRoot } from 'react-dom/client';

import App from './App';
import { PermissionsProvider } from './hooks/usePermissions';
import './index.css';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Responsive design adjustment for --vh
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

root.render(
    <PermissionsProvider>
      <App />,
    </PermissionsProvider>,
);
