import React from 'react';

import { Link } from 'react-router-dom';

import styles from './RedirectLink.module.scss';

const RedirectLink = ({ to, children }) => {
  return (
    <Link to={to} className={styles.redirectLink}>
      {children}
    </Link>
  );
};

export default RedirectLink;
