import React from 'react';

import { Dialog } from '@headlessui/react';

import styles from './ModalPopUp.module.scss';

const ModalPopUp = ({ isOpen, onClose, title, description, children }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className={styles.modalOverlay}>
      <Dialog.Panel className={styles.modal}>
        <Dialog.Title className={styles.modalTitle}>{title}</Dialog.Title>
        <Dialog.Description as='div' className={styles.modalDescription}>
          {description}
        </Dialog.Description>
        {children}
      </Dialog.Panel>
    </Dialog>
  );
};

export default ModalPopUp;
