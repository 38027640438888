import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import styles from './CampaignList.module.scss';
import CreateCampaignConfirmation from './CreateCampaignConfirmation';
import { DataSourceConnectionContent } from
  '../../components/DataSourceConnectionContent/DataSourceConnectionContent';
import ABSplitField from '../../components/Inputs/ABSplitField';
import ListComponent from '../../components/ListComponent/ListComponent';
import MainLayout from '../../components/MainLayout/MainLayout';
import ModalMultipartForm from '../../components/Modal/ModalMultipartForm';
import Spinner from '../../components/Spinner/Spinner';
import { PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';
import CampaignService from '../../services/CampaignService';
import CompanyService from '../../services/CompanyService';

function CampaignList() {
  const [campaignList, setCampaignList] = useState(null);
  const [addCampaignFormOpen, setAddCampaignFormOpen] = useState(false);
  const [confirmAddCampaignFormOpen, setConfirmAddCampaignFormOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [clientDropdownOptions, setClientDropdownOptions] = useState([]);
  const [letterPaths, setLetterPaths] = useState([]);
  const [envelopePaths, setEnvelopePaths] = useState([]);
  const [mailableCount, setMailableCount] = useState(null);
  const [companyToIdMapping, setCompanyToIdMapping] = useState({});
  const [indexFormPageOpen, setIndexFormPageOpen] = useState();
  const [loading, setLoading] = useState(false);
  const {userPermissions} = usePermissions();
  const isAdmin = userPermissions.role === 'Admin';
  const isPPAcount = userPermissions.companyId === PAPERPLANES_ID;

  const columns = [
    'campaignName',
    'company',
    'campaignType',
    'dataSource',
    'activationTime',
    'abSplit',
    'status',
    'campaignActions',
  ];

  const loadCompanyList = async () => {
    const getCompanyListResult = await CompanyService.getCompanyList();

    if (getCompanyListResult.success) {
      const companies = getCompanyListResult.data.filter(
          (company) => company.status === 'Active',
      ).map((company) => {
        return {label: company.name, value: company.name};
      });
      setClientDropdownOptions(companies.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      }));
      setCompanyToIdMapping(getCompanyListResult.data.reduce((accumulator, company) => {
        accumulator[company.name] = company.mIdsite;
        return accumulator;
      }, {}));
    } else {
      toast.error('Failed to load companies. Please try again.');
      setClientDropdownOptions([{label: 'No clients!', value: ''}]);
    }
  };

  const createCampaign = async (data) => {
    try {
      if (!data.hasOwnProperty('company')) data.company = userPermissions.companyName;
      console.log('Creating campaign with parameters: ' + JSON.stringify(data));

      const response = await CampaignService.createCampaign(data);

      // console.log(response);

      if (response.success) {
        setAddCampaignFormOpen(false);
        loadCampaignList();
      } else {
        if (response.error && response.error.errors) {
          response.error.errors.map((err) => toast.error(err.detail));
        }
      }
    } catch (e) {
      toast.error('An error occured creating campaign');
    }
  };

  const saveCampaignDraft = async (data) => {
    if (!data) return;
    try {
      console.log(`Saving campaign as a draft: ${data}`);
      // remove unneccessary fields when sending eg. isTrusted

      const response = await CampaignService.createCampaignDraft({});

      if (response.success) {
        // TODO: reload to clear the form and load new campaign with draft status
        // location.reload();
      } else {
        if (response.error && response.error.errors) {
          const errorMessages = response.error.errors.map((err) => err.detail);
          toast.error(errorMessages);
        }
      }
    } catch (e) {
      toast.error('An error occured creating campaign as a draft');
    }
  };

  const loadCampaignList = async () => {
    setLoading(true);
    const getCampaignList = await CampaignService.getCampaignList();

    if (getCampaignList.success) {
      setCampaignList(getCampaignList.data.map((campaign) => ({
        ...campaign,
        refresh: loadCampaignList,
      })));
    } else {
      toast.error('Failed to load campaigns. Please try again');
    }
    setLoading(false);
  };

  const loadLetterPaths = async (company) => {
    const getLetterPaths = await CompanyService.getCompanyLetterPaths(
        companyToIdMapping[company] || userPermissions.companyId);

    if (getLetterPaths.success) {
      setLetterPaths(getLetterPaths.data.map((letterPath, index) => {
        return {label: letterPath, value: letterPath, selected: index == 0};
      }));
    } else {
      toast.error('Failed to load company letter templates. Please try again');
    }
  };

  const loadEnvelopePaths = async (company) => {
    const getEnvelopePaths = await CompanyService.getCompanyEnvelopePaths(
        companyToIdMapping[company] || userPermissions.companyId,
    );

    if (getEnvelopePaths.success) {
      setEnvelopePaths(getEnvelopePaths.data.map((envelopePath, index) => {
        return {label: envelopePath, value: envelopePath, selected: index == 0};
      }));
    } else {
      toast.error('Failed to load company envelope templates. Please try again');
    }
  };

  const getMailableCount = async (client, listId) => {
    const response = await CampaignService.getMailableCustomers(
        companyToIdMapping[client] || userPermissions.companyId, listId);
    if (!response.success) toast.error('Segment ID doesn\'t exist');
    setMailableCount(response.count);
    return response.success;
  };

  const formConfigs = [
    {
      step: 'Settings',
      description: 'Campaign Type',
      config: [
        {
          name: 'campaign_type',
          type: 'radio',
          values: [
            {label: 'Ongoing Campaign', value: 'ongoing-campaign', checked: true},
            {label: 'One-off Campaign', value: 'one-off-campaign', disabled: true},
          ],
          validation: {
            required: 'Empty!',
          },
        },
      ],
    },
    {
      step: 'Settings',
      description: 'Data source',
      config: [
        {
          name: 'data_source',
          type: 'radio',
          values: [
            {label: 'Klaviyo', value: 'klaviyo', checked: true},
          ],
          validation: {
            required: 'Empty!',
          },
        },
      ],
    },
    {
      step: 'Settings',
      description: 'Campaign Settings',
      execute: getMailableCount,
      execArgsFormKeys: ['company', 'list_id'],
      config: [
        isPPAcount ? {
          name: 'company',
          label: 'Client',
          type: 'dropdown',
          dropdownValues: clientDropdownOptions,
        } : null,
        {
          name: 'campaign_name',
          label: 'Campaign Name',
          type: 'text',
          placeholder: 'Name for the campaign',
          validation: {
            required: 'Empty!',
          },
        },
        {
          name: 'list_id',
          label: 'Segment ID',
          type: 'text',
          placeholder: 'Klaviyo Segment ID',
          validation: {
            required: 'Empty!',
          },
        },
        {
          type: 'custom',
          component: <ABSplitField/>,
        },
        {
          name: 'cost_per_send',
          label: 'Cost Per Send',
          type: 'decimal',
          unit: '£',
          toggled: {default: true},
          defaultValue: 0.85,
          min: 0,
          validation: {
            min: 0,
          },
        },
        {
          name: 'suppression_period',
          label: 'Suppression period',
          type: 'number',
          unit: 'days',
          toggled: {default: true},
          defaultValue: 30,
          min: 0,
          max: 365,
          validation: {
            min: 0,
            max: 365,
          },
        },
        {
          name: 'control_split',
          label: 'Control split',
          type: 'number',
          unit: '%',
          toggled: {default: true},
          defaultValue: 15,
          min: 0,
          max: 100,
          validation: {
            min: 0,
            max: 100,
          },
        },
        {
          name: 'daily_send_limit',
          label: 'Daily Send Limit',
          type: 'number',
          unit: 'mails',
          toggled: {default: false},
          defaultValue: 0,
          min: 0,
          validation: {
            min: 0,
          },
        },
      ].filter(Boolean),
    },
    {
      title: 'Data Source Connection',
      step: 'Settings',
      content: <DataSourceConnectionContent mailableCount={mailableCount} />,
      data: mailableCount,
      execute: loadLetterPaths,
      execArgsFormKeys: ['company'],
    },
    {
      step: 'Creative',
      description: 'Select Letter',
      execute: loadEnvelopePaths,
      execArgsFormKeys: ['company'],
      config: [
        {
          name: 'letter_path_a',
          label: 'a_b_split' in formData && 'variant_a_name' in formData &&
            formData['a_b_split'] < 100 ? `Letter ${formData['variant_a_name']}` : '',
          type: 'dropdown',
          dropdownValues: letterPaths,
          validation: {
            required: 'Empty!',
          },
        },
        'a_b_split' in formData && 'variant_b_name' in formData &&
          formData['a_b_split'] < 100 ? {
            name: 'letter_path_b',
            label: `Letter ${formData['variant_b_name']}`,
            type: 'dropdown',
            dropdownValues: letterPaths,
          } : {},
      ],
    },
    {
      step: 'Creative',
      description: 'Select Outer Envelope',
      config: [
        {
          name: 'envelope_path_a',
          label: 'a_b_split' in formData && 'variant_a_name' in formData &&
            formData['a_b_split'] < 100 ? `Envelope ${formData['variant_a_name']}` : '',
          type: 'dropdown',
          dropdownValues: envelopePaths,
          validation: {
            required: 'Empty!',
          },
        },
        'a_b_split' in formData && 'variant_b_name' in formData &&
          formData['a_b_split'] < 100 ? {
            name: 'envelope_path_b',
            label: `Envelope ${formData['variant_b_name']}`,
            type: 'dropdown',
            dropdownValues: envelopePaths,
          } : {},
      ],
    },
  ];

  useEffect(() => {
    loadCampaignList();
    if (isPPAcount) loadCompanyList();
    saveCampaignDraft(localStorage.getItem('campaignDraft'));
    localStorage.removeItem('campaignDraft');
  }, []);

  return (
    <MainLayout>
      <div className={styles.campaignTableHeader}>
        <h1 className={styles.heading}>Campaigns</h1>
        {isAdmin && <button
          className={styles.addCampaignButton}
          onClick={() => setAddCampaignFormOpen(true)}
        >New Campaign</button>}
        <ModalMultipartForm
          isOpen={addCampaignFormOpen}
          title='Add campaign'
          onSubmit={(data) => {
            data['activation_time'] = new Date().toLocaleTimeString(
                [], {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: undefined,
                  timeZoneName: undefined,
                  hour12: false,
                },
            );
            console.log(`Activation time ${data['activation_time']}`);
            setFormData(data);
            setConfirmAddCampaignFormOpen(true);
            // workaround to fix campaign summary screen submission
            setIndexFormPageOpen((prevVal) => prevVal += 1);
            // so we dont get a duplicate of an already created campaign
            localStorage.removeItem('campaignDraft');
          }}
          onSave={(data) => {
            saveCampaignDraft(JSON.stringify(data));
            localStorage.removeItem('campaignDraft');
          }}
          onClose={() => {
            setAddCampaignFormOpen(false);
          }}
          stepLabels={['Settings', 'Creative', 'Activation']}
          formData={formData}
          setFormData={setFormData}
          indexFormPageOpen={indexFormPageOpen}
          setIndexFormPageOpen={setIndexFormPageOpen}
          formConfigs={formConfigs}
        />
        <CreateCampaignConfirmation
          isOpen={confirmAddCampaignFormOpen}
          onClose={() => {
            setIndexFormPageOpen(formConfigs.length - 1);
            setConfirmAddCampaignFormOpen(false);
          }}
          onConfirm={() => {
            createCampaign(formData);
            setAddCampaignFormOpen(false);
            setConfirmAddCampaignFormOpen(false);
          }}
          stepLabels={['Settings', 'Creative', 'Activation']}
          data={formData}
        />
      </div>
      <div style={{overflow: 'auto', height: '80vh'}}>
        {!loading && campaignList && (<ListComponent columns={columns} data={campaignList}/>)}
      </div>
      <Spinner loading={loading} />
    </MainLayout>
  );
}

export default CampaignList;
