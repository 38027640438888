import ApiService from './ApiService';

const PdfService = {
  getPdfList: async () => {
    const response = await ApiService.get('/creative_upload/upload_pdf');
    // console.log(`Response GET: ${JSON.stringify(response)}`);
    return response;
  },
  uploadPdfs: async (pdfs) => {
    const uploadPromises = pdfs.map((pdf) =>{
      const formData = new FormData();
      formData.append('file', pdf);
      return ApiService.postFormData('/creative_upload/upload_pdf/', formData);
    });
    const results = await Promise.all(uploadPromises);
    return results;
  },
};

export default PdfService;
