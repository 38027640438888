import React from 'react';

import styles from './InputField.module.scss';

const InputField = ({ type, placeholder, value, onChange, toggleIcon, onToggleIconClick }) => {
  return (
    <div className={styles.inputContainer}>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={styles.input}
      />
      {toggleIcon && (
        <img
          src={toggleIcon}
          alt="Toggle"
          className={styles.toggleIcon}
          onClick={onToggleIconClick}
        />
      )}
    </div>
  );
};

export default InputField;
