import React, { useState, useEffect } from 'react';

import { Menu, Transition } from '@headlessui/react';
import { useHistory, useLocation } from 'react-router-dom';
// import { toast } from 'react-toastify';

import styles from './Header.module.scss';
// import bellIcon from '../../assets/images/header-bell.png';
import inflightIcon from '../../assets/images/header-inflight-logo.png';
import logoIcon from '../../assets/images/header-pp-logo.png';
// import CompanyService from '../../servsnoices/CompanyService';
import UserService from '../../services/UserService';

// const BellIcon = () => (
//   <button className={styles.bellIconButton}>
//     <img src={bellIcon} alt="Bell" className={styles.bellIcon} />
//   </button>
// );

const UserPhoto = ({onClick, formattedName}) => {
  return (
    <div className={styles.dropdownContainer}>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button className={styles.userPhotoButton}>
              <span className={styles.userName}>{formattedName}</span>
              {/* <img src={src} alt="User" className={styles.userPhoto} /> */}
            </Menu.Button>

            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className={styles.menuItems}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={onClick}
                    >LOG OUT</button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};

function Header({logOut}) {
  const [formattedName, setFormattedName] = useState('');
  const [companyLogo, setCompanyLogo] = useState(null);
  const selectedIcon = useLocation().pathname;
  const history = useHistory();

  useEffect(() => {
    async function getUserData() {
      const userData = (await UserService.getCurrentUserData()).data;
      getInitials(userData);
      setCompanyLogo(userData.logoUrl);
    }
    getUserData();
  }, []);

  function getInitials(userData) {
    const fNameInitial = userData.firstName ? userData.firstName.charAt(0) : '';
    const lNameInitial = userData.lastName ? userData.lastName.charAt(0) : '';
    setFormattedName(fNameInitial.concat(lNameInitial));
  }

  return (
    <div className={styles.header}>
      <img
        src={selectedIcon === '/dashboard' ? inflightIcon : logoIcon}
        alt="Logo" className={styles.logo}
        onClick={() => history.push('/login')}
      />
      <div className={styles.userInfo}>
        {/* <BellIcon /> */}
        {companyLogo && <img className={styles.companyLogo} src={companyLogo} />}
        <UserPhoto onClick={logOut} formattedName={formattedName} />
      </div>
    </div>
  );
}

export default Header;
