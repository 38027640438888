import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './UserList.module.scss';
import ListComponent from '../../components/ListComponent/ListComponent';
import MainLayout from '../../components/MainLayout/MainLayout';
import Spinner from '../../components/Spinner/Spinner';
import UserDetailsForm from '../../components/UserDetailsForm/UserDetailsForm';
import { usePermissions } from '../../hooks/usePermissions';
import UserService from '../../services/UserService';

function UserList() {
  const [userList, setUserList] = useState(null);
  const [createUserPopUpOpen, setCreateUserPopUpOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {userPermissions} = usePermissions();
  const isAdmin = userPermissions.role === 'Admin';

  const columns = [
    'email', 'firstName', 'lastName', 'companyName', 'role', 'status', 'userActions',
  ];

  const processErrorResponse = (response, defaultMessage) => {
    if (response.error && response.error.errors) {
      return response.error.errors.map((err) => err.detail).join('\n');
    }
    return defaultMessage;
  };

  const createUser = async (data) => {
    setCreateUserPopUpOpen(false);
    const { fname, lname, email, role, companyID = userPermissions.companyId } = data;
    try {
      const createUserResponse = await UserService.createUser(
          email.toLowerCase(), fname, lname, role, companyID);

      if (createUserResponse.success) {
        const inviteResponse = await UserService.inviteUser(email.toLowerCase());

        if (inviteResponse.success) {
          toast.success('User created and invitation sent!');
        } else {
          toast.error(
              processErrorResponse(inviteResponse, 'User created, but failed to send invitation.'),
          );
        }
        // TODO to be fixed with hook for user list
        loadUserList();
      } else {
        toast.error(processErrorResponse(createUserResponse, 'Error creating a new user'));
      }
    } catch (e) {
      toast.error('An error occurred creating a new user');
    }
  };

  const loadUserList = async () => {
    setLoading(true);
    const getUserListResult = await UserService.getUserList();

    if (getUserListResult.success) {
      setUserList(getUserListResult.data.map((user) => ({
        ...user,
        refresh: loadUserList,
      })));
    } else {
      toast.error('Failed to load users. Please try again.');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUserList();
  }, []);

  return (
    <MainLayout>
      <div className={styles.userTableHeader}>
        <h1 className={styles.heading}>Users</h1>
        {isAdmin && <button
          className={styles.addUserButton}
          onClick={() => setCreateUserPopUpOpen(true)}
        >Add User</button>}
        <UserDetailsForm
          popUpOpen={createUserPopUpOpen}
          setPopUpOpen={setCreateUserPopUpOpen}
          formTitle='Add User'
          onSubmit={(data) => createUser(data)}
        />
      </div>
      <div style={{overflow: 'auto', height: '80vh'}}>
        {!loading && userList && (<ListComponent columns={columns} data={userList}/>)}
      </div>
      <Spinner loading={loading} />
    </MainLayout>
  );
}

export default UserList;
