import React from 'react';

import { Route, Redirect } from 'react-router-dom';

export default function ProtectedRoute({ component: Component, condition, ...rest}) {
  return <Route {...rest} render={(props) => condition ? (
      <Component {...props} />
    ) : (
      <Redirect to='/dashboard' />
    )
  }
  />;
};
