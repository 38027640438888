import ApiService from './ApiService';

const ApiKeyService = {
  uploadApiKey: async (body) => {
    return await ApiService.post('/api_key_upload/upload/', { body });
  },
  checkApiKey: async (service) => {
    return await ApiService.get(`/api_key_upload/check/${service}/`);
  },
};

export default ApiKeyService;
