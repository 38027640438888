import React from 'react';

import styles from './AuthLayout.module.scss';
import loginLogoIcon from '../../assets/images/login-pp-logo.png';
import loginLogoIcon2x from '../../assets/images/login-pp-logo@2x.png';
import loginLogoIcon3x from '../../assets/images/login-pp-logo@3x.png';

const AuthLayout = ({ title, children, link }) => {
  return (
    <div className={styles.container}>
      <img
        src={loginLogoIcon}
        alt="Logo"
        className={styles.logo}
        srcSet={`${loginLogoIcon2x} 2x, ${loginLogoIcon3x} 3x`}
      />
      <div className={styles.box}>
        <h2 className={styles.title}>{title}</h2>
        {children}
      </div>
      {link}
    </div>
  );
};

export default AuthLayout;
