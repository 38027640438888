import React from 'react';

import { ClipLoader } from 'react-spinners';


const Spinner = ({ loading }) => {
  const style = { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };

  return (
    <div style={style}>
      <ClipLoader size={100} color={'#6A99CF'} loading={loading} />
    </div>
  );
};

export default Spinner;
