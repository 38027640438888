import React, {useEffect, useState} from 'react';

import styles from './ABSplitField.module.scss';

const ABSplitField = ({register, watch, setValue, errors,
  defaultSplit, variantAName, variantBName}) => {
  const [abEnabled, setABEnabled] = useState(defaultSplit && defaultSplit !== 100 ? true : false);
  const [bSplit, setBSplit] = useState(0);

  const watchABSplit = watch('a_b_split');

  useEffect(() => {
    if (abEnabled) setValue('a_b_split', defaultSplit);
  }, []);

  useEffect(() => {
    if (!abEnabled) setValue('a_b_split', 100);
  }, [abEnabled]);

  useEffect(() => {
    if (watchABSplit !== undefined) {
      setBSplit(100 - watchABSplit);
    }
  }, [watchABSplit]);

  return (
    <div style={{display: 'grid', gap: '10px'}}>
      <div className={styles.formGroup}>
        <span className={styles.togglableGroup}>
          <input
            type='checkbox'
            className={styles.checkbox}
            checked={abEnabled}
            onChange={(e) => {
              setABEnabled(e.target.checked);
              setValue('a_b_split', 50);
            }}
          />
          <span className={styles.largeLabel}>A/B split</span>
        </span>
      </div>
      {abEnabled && <div style={{display: 'flex'}}>
        <div className={styles.formGroup}>
          <label style={{ color: errors['variant_a_name'] ? 'red' : '' }} htmlFor='variant_a_name'>
            Variant A Name
          </label>
          <input
            id='variant_a_name'
            name='variant_a_name'
            type='text'
            defaultValue={variantAName}
            {...register('variant_a_name', {
              required: 'Empty!',
            })}
            placeholder='Enter name for variant A'
            className={errors['variant_a_name'] ? styles.error : ''}
            spellCheck={false}
          />
          {errors['variant_a_name'] && <span className={styles.errorMessage}>
            {errors['variant_a_name'].message}
          </span>}
        </div>
        <div className={styles.formGroup}>
          <label style={{ color: errors['a_b_split'] ? 'red' : '' }} htmlFor='a_b_split'>
            Probability of Variant A
          </label>
          <span>
            <input
              id='a_b_split'
              name='a_b_split'
              type='number'
              min='0'
              max='100'
              {...register('a_b_split', {
                valueAsNumber: true,
                min: 0,
                max: 100,
              })}
              className={errors['a_b_split'] ? styles.error : ''}
            />
            <span className={styles.inputNumberUnit}>%</span>
          </span>
          {errors['a_b_split'] && <span className={styles.errorMessage}>
            {errors['a_b_split'].message}
          </span>}
        </div>
      </div>}
      {abEnabled && <div style={{display: 'flex'}}>
        <div className={styles.formGroup}>
          <label style={{ color: errors['variant_b_name'] ? 'red' : '' }} htmlFor='variant_b_name'>
            Variant B Name
          </label>
          <input
            id='variant_b_name'
            name='variant_b_name'
            type='text'
            defaultValue={variantBName}
            {...register('variant_b_name', {
              required: 'Empty!',
            })}
            placeholder='Enter name for variant B'
            className={errors['variant_b_name'] ? styles.error : ''}
            spellCheck={false}
          />
          {errors['variant_b_name'] && <span className={styles.errorMessage}>
            {errors['variant_b_name'].message}
          </span>}
        </div>
        <div className={styles.formGroup}>
          <label style={{ color: errors['a_b_split'] ? 'red' : '' }} htmlFor='a_b_split'>
            Probability of Variant B
          </label>
          <span><input
            type='number'
            value={bSplit}
            min='0'
            max='100'
            onChange={(event) => {
              setValue('a_b_split', 100 - +event.target.value);
            }}
            className={errors['a_b_split'] ? styles.error : ''}
          />
          <span className={styles.inputNumberUnit}>%</span>
          </span>
        </div>
      </div>}
    </div>
  );
};

export default ABSplitField;
