import React from 'react';

import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatusIndicator = ({ status }) => {
  const color = status === 'Active' ? '#5b9bd4' : 'grey';
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon
        icon={faCircle}
        size='xs'
        style={{ color: color, marginRight: '5px' }}
      />
      {status}
    </div>
  );
};

export default StatusIndicator;
