
import React from 'react';

import styles from './PrimaryActionButton.module.scss';

const PrimaryActionButton = ({ onClick, children, type }) => {
  const buttonClass = type === 'secondary' ?
    styles.secondary : styles.primary;
  return (
    <button className={`${styles.actionButton} ${buttonClass}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default PrimaryActionButton;
