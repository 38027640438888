import ApiService from './ApiService';


export const storeAuthToken = (authToken) => {
  localStorage.setItem('fpp-auth-token', authToken);
};

export const clearAuthToken = () => {
  localStorage.removeItem('fpp-auth-token');
  localStorage.removeItem('userData');
};

export const retrieveAuthHeader = () => {
  const token = localStorage.getItem('fpp-auth-token');
  if (token) {
    return { 'Authorization': `Token ${token}` };
  }
  return {};
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('fpp-auth-token');
};

export const authenticateUser = async (email, password) => {
  const response = await ApiService.post('/users/login/', { username: email, password });

  if (response.success) {
    if (response.data && response.data.token) {
      storeAuthToken(response.data.token);
      return { success: true, token: response.data.token };
    } else {
      return { success: false, error: 'Login succeeded, but no token was provided.' };
    }
  } else {
    return { success: false, error: response.error || 'Authentication failed. Please try again.' };
  }
};

export const activateUser = async (token, password) => {
  const response = await ApiService.post('/users/activate/', { token, password });

  if (response.success) {
    return { success: true };
  } else {
    return { success: false, error: response.error || 'Activation failed. Please try again.' };
  }
};
