import React, { useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import eyeHiddenIcon from '../../assets/images/eye-hidden.png';
import eyeShownIcon from '../../assets/images/eye-shown.png';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import PrimaryActionButton from '../../components/Buttons/PrimaryActionButton';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import InputField from '../../components/Inputs/InputField';
import RedirectLink from '../../components/Links/RedirectLink';
import { clearAuthToken } from '../../services/AuthService';
import UserService from '../../services/UserService';


function SetPassword({ endpoint, title, userMsgs }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');


  useEffect(() => {
    const checkToken = async () => {
      if (!token) history.push('/login');
      else {
        const response = await UserService.validateUrlToken(token);
        if (!response.success) {
          history.push('/login');
          const errorMessage = title === 'Reset Your Password' ?
          'Please request another email to reset your password' :
          'Please request another invitation email';
          toast.error(`Invalid Token! ${errorMessage}`);
        } else {
          clearAuthToken();
        }
      }
    };

    checkToken();
  }, [token, history]);


  const handleActivation = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords don\'t match.');
      return;
    }

    try {
      const response = await endpoint(token, password);

      if (response.success) {
        setIsSuccess(true);
      } else {
        if (response.error && response.error.errors) {
          const errorMessages = response.error.errors.map((err) => err.detail);
          setError(errorMessages);
        } else {
          setError(userMsgs.error);
        }
      }
    } catch (e) {
      setError(userMsgs.catchError);
    }
  };


  if (isSuccess) {
    return (
      <AuthLayout
        title={userMsgs.success}
      >
        <p>Your password has been set. You may go and log in now.</p>
        <form onSubmit={(e) => {
          e.preventDefault();
          history.push('/login');
        }}>
          <PrimaryActionButton>Go To Login</PrimaryActionButton>
        </form>
      </AuthLayout>
    );
  }


  return (
    <AuthLayout
      title={title}
      link={<RedirectLink to="/login">Go to Log In</RedirectLink>}
    >

      <form onSubmit={handleActivation}>
        <InputField
          type={showPassword ? 'text' : 'password'}
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          toggleIcon={showPassword ? eyeShownIcon : eyeHiddenIcon}
          onToggleIconClick={() => setShowPassword(!showPassword)}
        />

        <InputField
          type={showConfirmPassword ? 'text' : 'password'}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          toggleIcon={showConfirmPassword ? eyeShownIcon : eyeHiddenIcon}
          onToggleIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
        />

        <ErrorMessage messages={error} />

        <PrimaryActionButton>{title.split(' ')[0].toUpperCase()}</PrimaryActionButton>
      </form>

    </AuthLayout>
  );
}

export default SetPassword;
