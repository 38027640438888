import ApiService from './ApiService';

const CompanyService = {
  getCompanyList: async () => {
    return await ApiService.get('/companies/');
  },
  getCompanyLetterPaths: async (id) => {
    return await ApiService.get(`/companies/${id}/letters`);
  },
  getCompanyEnvelopePaths: async (id) => {
    return await ApiService.get(`/companies/${id}/envelopes`);
  },
  changeCompanyStatus: async (id, status) => {
    return await ApiService.patch(`/companies/${id}/`, { 'status': status });
  },
  editCompany: async (id, data) => {
    return await ApiService.patch(`/companies/${id}/`, data);
  },
  createCompany: async (data) => {
    return await ApiService.post(`/companies/`, { data });
  },
  deleteCompany: async (id) => {
    return await ApiService.delete(`/companies/${id}`);
  },
};

export default CompanyService;
