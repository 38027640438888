import { useState, useEffect } from 'react';

import { isAuthenticated } from '../services/AuthService';

export default function useAuth() {
  const [isAuth, setIsAuth] = useState(isAuthenticated());

  useEffect(() => {
    // This effect will re-run if the authentication status changes.
    setIsAuth(isAuthenticated());
  }, [isAuth]);

  return [isAuth, setIsAuth];
};
