import React from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ModalForm from '../../components/Modal/ModalForm';
import UserService from '../../services/UserService';

function ResetPassword() {
  const history = useHistory();

  const sendEmail = async (email) => {
    try {
      await UserService.sendResetPasswordToken(email);
      toast.info('If this user exists, then a password reset email' +
      ` will be sent to ${email}.`);
    } catch (e) {
      toast.error(`An error occured sending a reset password email to ${email}`);
    }
    return false;
  };

  return (
    <ModalForm
      isOpen={true}
      onClose={() => {
        history.push('/login');
        toast.dismiss();
      }}
      title='Forgot your password?'
      onSubmit={(data) => sendEmail(data.email)}
      description={'Enter your email address below to reset your password'}
      config={[
        {
          name: 'email',
          type: 'text',
          validation: {
            required: 'Empty!',
            pattern: {
              value: new RegExp(
                  `^(([^<>()[\\]\\.,;:\\s@\"]+(\\.[^<>()[\\]\\.,;:\\s@\"]+)*)|(\\".+\\"))@` +
                  `((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\])|` +
                  `(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`,
              ),
              message: 'Enter a valid email!',
            },
          },
        },
      ]}
    />
  );
}
export default ResetPassword;
