import React from 'react';

import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './DataSourceConnectionContent.module.scss';

export const DataSourceConnectionContent = (props) => {
  const { mailableCount } = props;
  const hasMailableCount = props.hasOwnProperty('mailableCount');

  return (
    <div className={styles.middleContainer}>
      <FontAwesomeIcon
        className={styles.icon}
        icon={hasMailableCount || !hasMailableCount ? faCircleCheck : faCircleExclamation}
      />
      <div className={styles.middleContainer}>
        {hasMailableCount ? (
          mailableCount ? (
            <>
              <p>Success!</p>
              <p>{mailableCount} mailable customers.</p>
            </>
          ) : (
            <>
              <p>0 mailable customers!</p>
              <p>Are you sure you want to continue?</p>
            </>
          )
        ) : (
          <p>Success!</p>
        )}
      </div>
    </div>
  );
};
