import React, { useState, useCallback, useEffect } from 'react';

import { faXmark, faFileArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useDropzone} from 'react-dropzone';
import { toast } from 'react-toastify';

import styles from './Upload.module.scss';
import PrimaryActionButton from '../../components/Buttons/PrimaryActionButton';
import ListComponent from '../../components/ListComponent/ListComponent';
import MainLayout from '../../components/MainLayout/MainLayout';
import Spinner from '../../components/Spinner/Spinner';
import { PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';
import PdfService from '../../services/PdfService';

function Upload() {
  const {userPermissions} = usePermissions();
  const [pdfList, setPdfList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;

  const columns = [
    'fileName', 'dateUploaded', 'fileSize',
  ];
  if (isPPAccount) columns.splice(1, 0, 'company');

  const onDrop = useCallback((acceptedFiles) => {
    setFilesToUpload(acceptedFiles);
  }, [filesToUpload]);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
    },
  });

  const removeFile = (file) => {
    const newFiles = [...filesToUpload];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFilesToUpload(newFiles);
  };

  const removeAll = () => {
    setFilesToUpload([]);
  };

  const files = filesToUpload.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <button onClick={() => removeFile(file)}><FontAwesomeIcon icon={faXmark} /></button>
    </li>
  ));

  const submitFiles = async () => {
    if (filesToUpload.length < 1) {
      toast.error('No PDFs selected');
      return;
    }
    const uploadedPdf = await PdfService.uploadPdfs(filesToUpload);
    for (const resp of uploadedPdf) {
      if (resp.success) toast.success('PDF uploaded successfully');
      else toast.error('Failed to upload PDF');
    }
    loadPDFs();
    removeAll();
  };

  const loadPDFs = async () => {
    setLoading(true);
    const getPdfList = await PdfService.getPdfList();

    if (getPdfList.success) {
      setPdfList(getPdfList.data.pdfFiles);
    } else {
      toast.error('Failed to load PDFs. Please try again.');
    };
    setLoading(false);
  };

  useEffect(() => {
    loadPDFs();
    // console.log(pdfList);
  }, []);

  return (
    <MainLayout>
      <div className={`${styles.uploadContainer} ${!isPPAccount ? styles.middleSeparator : ''}`}>
        <div className={styles.table}>
          <div style={{overflow: 'auto', height: '80vh', width: '35vw'}}>
            <ListComponent columns={columns} data={pdfList} />
          </div>
          <Spinner loading={loading} />
        </div>
        {!isPPAccount && <div className={styles.uploadButton}>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <h2>
              <FontAwesomeIcon icon={faFileArrowUp} size='2x'/><br />
              Drag & Drop PDFs here,<br />
              or <span>choose PDFs</span>
            </h2>
          </div>
          <aside>
            <PrimaryActionButton onClick={submitFiles}>Submit Files</PrimaryActionButton>
            <h4>Files</h4>
            <ul>{files}</ul>
          </aside>
        </div>}
      </div>
    </MainLayout>
  );
}

export default Upload;
