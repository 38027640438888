import React from 'react';

import DataTable from 'react-data-table-component';

import { allColumnDefinitions } from './ColumnDefinitions';
import { usePermissions } from '../../hooks/usePermissions';


function ListComponent({ title, columns, data, formData }) {
  /**
   * Important! Each value in columns should have a corresponding mapping
   * in ColumnDefinitions.js
   * For possible fields refer to:
   * https://react-data-table-component.netlify.app/?path=/docs/api-columns--page
   */
  console.assert(columns.every((column) => Object.keys(allColumnDefinitions).includes(column)));
  const {userPermissions} = usePermissions();
  const isAdmin = userPermissions.role === 'Admin';
  const columnDefs = columns.filter((column) => {
    if (!isAdmin) return !column.endsWith('Actions');
    else return column;
  }).map((column) => allColumnDefinitions[column]);

  const customStyles = {
    table: {
      style: {
        height: 'auto',
      },
    },
    responsiveWrapper: {
      style: {
        overflow: 'visible',
      },
    },
    head: {
      style: {
        zIndex: '1000',
      },
    },
    headRow: {
      style: {
        color: '#5b9bd4',
        fontWeight: '700',
      },
    },
    rows: {
      style: {
        minHeight: '43px',
      },
    },
    pagination: {
      style: {
        position: 'relative',
        bottom: 0,
        left: 0,
        // zIndex: 1,
        minHeight: '45px',
      },
    },
  };

  return (
    <DataTable
      columns={columnDefs}
      data={data}
      pagination
      responsive
      fixedHeader
      fixedHeaderScrollHeight="auto"
      customStyles={customStyles}
    />
  );
}

export default ListComponent;

