import React, { useState } from 'react';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './MenuButton.scss';

import { usePermissions } from '../../hooks/usePermissions';
import UserService from '../../services/UserService';
import ModalForm from '../Modal/ModalForm';
import UserDetailsForm from '../UserDetailsForm/UserDetailsForm';


function UserMenuButton({ data, userId, refresh }) {
  const [editPopUpOpen, setEditPopUpOpen] = useState(false);
  const [changeStatusPopUpOpen, setChangeStatusPopUpOpen] = useState(false);
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [clickYAtBottom, setClickYAtBottom] = useState(false);
  const {userPermissions} = usePermissions();
  const isAdmin = userPermissions.role === 'Admin';

  const responseHandling = (response) => {
    if (response.success) {
      refresh();
    } else {
      if (response.error && response.error.errors) {
        const errorMessages = response.error.errors.map((err) => err.detail).join('\n');
        toast.error(errorMessages);
      }
    }
  };

  const deleteUser = async () => {
    setDeletePopUpOpen(false);
    try {
      const response = await UserService.deleteUser(userId);
      responseHandling(response);
    } catch (e) {
      toast.error('An error occured deleting selected user');
    }
  };
  const editUser = async (data) => {
    setEditPopUpOpen(false);
    const {fname, lname, role, companyID = userPermissions.companyId} = data;
    try {
      const response = await UserService.updateUser(userId, fname, lname, role, companyID);
      responseHandling(response);
    } catch (e) {
      toast.error('An error occured editing selected user');
    }
  };
  const changeUserStatus = async () => {
    setChangeStatusPopUpOpen(false);
    const newStatus = data.status === 'Active' ? 'Inactive' : 'Active';
    try {
      const response = await UserService.changeUserStatus(userId, newStatus);
      responseHandling(response);
    } catch (e) {
      toast.error('An error occured changing selected user\'s status');
    }
  };

  const handleMenuButtonClick = (event) => {
    if (event.clientY - 60 > window.innerHeight / 2) setClickYAtBottom(true);
    else setClickYAtBottom(false);
  };

  return (
    <div className="menu-container">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <Menu.Button className="menu-button" onClick={handleMenuButtonClick}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </Menu.Button>

            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className={`menu-items 
              ${clickYAtBottom ? 'menu-items-bottom' : ''}`}>
                {isAdmin && <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setEditPopUpOpen(true)}
                    >Edit profile</button>
                  )}
                </Menu.Item>}
                {data.status == 'Pending' && <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={async () => {
                        const inviteResponse = await UserService.inviteUser(data.email);

                        if (inviteResponse.success) {
                          toast.success('Invitation successfully sent!');
                        } else {
                          if (inviteResponse.error && inviteResponse.error.errors) {
                            toast.error(inviteResponse.error.errors.map(
                                (err) => err.detail).join('\n'));
                          } else toast.error('Failed to re-send invitation email');
                        }
                      }}
                    >Re-send Platform Invitation Email</button>
                  )}
                </Menu.Item>}
                {data.status != 'Pending' && isAdmin && <Menu.Item>
                  {({ active }) => (
                    <div>
                      <button
                        disabled={data.id === userPermissions.id}
                        className={`${active ? 'active' : ''}`}
                        onClick={() => setChangeStatusPopUpOpen(true)}
                      >{`Make user ${data.status === 'Active' ? 'inactive' : 'active'}`}</button>

                    </div>
                  )}
                </Menu.Item>}
                {isAdmin && <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setDeletePopUpOpen(true)}
                      disabled={data.id === userPermissions.id}
                    >
                      <span className={data.id !== userPermissions.id ? 'delete-button' : ''}>
                        Delete user</span>
                    </button>
                  )}
                </Menu.Item>}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <UserDetailsForm
        popUpOpen={editPopUpOpen}
        setPopUpOpen={setEditPopUpOpen}
        formTitle='Edit User'
        onSubmit={(data) => editUser(data)}
        placeholder={data}
      />
      <ModalForm
        isOpen={changeStatusPopUpOpen}
        onClose={() => setChangeStatusPopUpOpen(false)}
        title={`Make user ${data.status === 'Active' ? 'Inactive' : 'Active'}`}
        description={'Are you sure?'}
        onSubmit={() => changeUserStatus()}
      >
      </ModalForm>
      <ModalForm
        isOpen={deletePopUpOpen}
        onClose={() => setDeletePopUpOpen(false)}
        title='Delete user?'
        description={'This action cannot be reversed. If you are absolutely ' +
          'sure please type "permanently delete" in the box.'}
        onSubmit={() => deleteUser()}
        config={[
          {
            name: 'permanently-delete',
            label: 'Are you sure?',
            placeholder: 'permanently delete',
            type: 'text',
            validation: {
              required: 'Empty!',
              pattern: {
                value: /^permanently delete$/,
                message: 'Invalid!',
              },
            },
          },
        ]}
      />
    </div>
  );
}

export default UserMenuButton;
