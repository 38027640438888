import React, {useState, useEffect} from 'react';

import { PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';
import CompanyService from '../../services/CompanyService';
import ModalForm from '../Modal/ModalForm';

function UserDetailsForm({ popUpOpen, setPopUpOpen, formTitle, onSubmit, placeholder }) {
  const [companyList, setCompanyList] = useState(null);
  const {userPermissions} = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;
  const {email, firstName, lastName, role, companyName} = placeholder ?? '';
  const getCompanyList = async () => {
    const getCompanyListResult = await CompanyService.getCompanyList();
    if (getCompanyListResult.success) {
      const companies = getCompanyListResult.data.map(({ mIdsite, name }) => {
        return { value: mIdsite, label: name };
      });
      setCompanyList(companies.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      }));
    } else {
      setCompanyList([{label: 'No clients!', value: ''}]);
    }
  };

  useEffect(() => {
    if (isPPAccount) getCompanyList();
  }, []);

  return (
    <ModalForm
      isOpen={popUpOpen}
      onClose={() => setPopUpOpen(false)}
      title={formTitle}
      onSubmit={onSubmit}
      config={[
        {
          name: 'fname',
          label: 'First name',
          defaultValue: firstName,
          type: 'text',
          validation: {
            required: 'Empty!',
            minLength: 2,
            maxLength: 150,
            pattern: {
              value: /^[a-z ,.'-]*$/i,
              message: 'Name contains invalid characters!',
            },
          },
        },
        {
          name: 'lname',
          label: 'Last name',
          defaultValue: lastName,
          type: 'text',
          validation: {
            required: 'Empty!',
            minLength: 2,
            maxLength: 150,
            pattern: {
              value: /^[a-z ,.'-]*$/i,
              message: 'Name contains invalid characters!',
            },
          },
        },
        {
          name: 'email',
          label: 'Email',
          restricted: email,
          type: 'text',
          validation: {
            required: 'Empty!',
            pattern: {
              value: new RegExp(
                  `^(([^<>()[\\]\\.,;:\\s@\"]+(\\.[^<>()[\\]\\.,;:\\s@\"]+)*)|(\\".+\\"))@` +
                  `((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\])|` +
                  `(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`,
              ),
              message: 'Enter a valid email!',
            },
          },
        },
        {
          name: 'role',
          label: 'Role',
          type: 'dropdown',
          dropdownValues: [{label: 'User', value: 'User'}, {label: 'Admin', value: 'Admin'}],
          selected: role,
        },
        isPPAccount ? {
          name: 'companyID',
          label: 'Company',
          type: 'dropdown',
          dropdownValues: companyList ?? [''],
          selected: companyList?.find((company) => company.label === companyName)?.value,
        } : null,
      ].filter(Boolean)}
    />
  );
}

export default UserDetailsForm;
