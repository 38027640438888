import React from 'react';

import styles from './ErrorMessage.module.scss';

const ErrorMessage = ({ messages }) => {
  // Check if messages is an array, if not, make it an array
  const errorMessages = Array.isArray(messages) ? messages : [messages];

  return (
    <div className={styles.errorMessages}>
      {errorMessages.map((message, index) => (
        <p key={index} className={styles.errorMessage}>{message}</p>
      ))}
    </div>
  );
};

export default ErrorMessage;
